import React, { Component } from 'react';
import $ from 'jquery';
import './add_on.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class MotorSense extends Component {
  constructor(prop) {
    super(prop);

    this.picItem = [1, 2, 3];

    this.state = {
      carousel_item: [],
    };
  }

  componentDidMount() {
    var langs = JSON.parse(window.localStorage.getItem('language_data'));
    var slide_item = [];

    for (var keys in langs.MotorSense) {
      $(`#${keys}`).html(langs.MotorSense[keys]);

      if (
        keys == 'motor_2_s1' ||
        keys == 'motor_2_s2' ||
        keys == 'motor_2_s3'
      ) {
        slide_item.push(langs.MotorSense[keys]);
      }
    }

    this.setState({ carousel_item: slide_item });
  }

  render = () => {
    return (
      <div className="motor_sense">
        <div className="row r_1">
          <div className="col-xl">
            <img
              src={require('../images/arrange/1.png')}
              className="img-fluid"
            />
            <h2 id="motor_list_1"></h2>
          </div>
        </div>
        <div className="row r_2">
          <div className="col-xl-6">
            <p id="motor_1_p1"></p>

            <p id="motor_1_p2"></p>

            <p id="motor_1_p3"></p>

            <p>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#video_modal_motor"
              >
                Video Here
              </button>
            </p>
          </div>
          <div className="col-xl-6"></div>
        </div>
        <div className="row r_3">
          <div className="col-xl">
            <img
              src={require('../images/arrange/2.png')}
              className="img-fluid"
            />
            <h2>
              <nobr id="motor_list_2_1"></nobr> <span id="motor_list_2_2" />
            </h2>
          </div>
        </div>
        <div className="row r_4">
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            interval={5000}
            transitionTime={1000}
            dynamicHeight={true}
            emulateTouch={true}
            showStatus={false}
            className={'motorsense_slide'}
            axis={'vertical'}
          >
            {this.picItem.map((val, ind) => {
              return (
                <div className="row" key={ind}>
                  <div className="col-md-6 first">
                    <img
                      src={require(`../images/motorsense_pic/how-it-works0${
                        ind + 1
                      }-motorsense.jpg`)}
                    />
                  </div>
                  <div className="col-md-6 sec">
                    <p>{this.state.carousel_item[ind]}</p>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="row r_5">
          <div className="col-xl">
            <img
              src={require('../images/arrange/3.png')}
              className="img-fluid"
            />
            <h2 id="motor_list_3"></h2>
          </div>
        </div>
        <div className="row r_6">
          <div className="col-xl-6">
            <p>
              <strong id="motor_3_t1"></strong>
              <br />
              <span id="motor_3_p1"></span>
            </p>

            <p>
              <strong id="motor_3_t2"></strong>
              <br />
              <span id="motor_3_p2"></span>
            </p>

            <p>
              <strong id="motor_3_t3"></strong>
              <br />
              <span id="motor_3_p3"></span>
            </p>

            <p>
              <strong id="motor_3_t4"></strong>
              <br />
              <span id="motor_3_p4"></span>
            </p>
          </div>
        </div>
        <div className="row r_7">
          <div className="col-xl">
            <img
              src={require('../images/arrange/4.png')}
              className="img-fluid"
            />
            <h2 id="motor_list_4"></h2>
          </div>
        </div>
        <div className="row r_8">
          <div className="col-xl-6">
            <p id="motor_4_p1"></p>

            <p id="motor_4_p2"></p>

            <p id="motor_4_p3"></p>
          </div>
        </div>
        <MotorSenseVideoModal />
      </div>
    );
  };
}

export default MotorSense;

class MotorSenseVideoModal extends Component {
  render = () => {
    return (
      <div className="modal fade" role="dialog" id="video_modal_motor">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <video
              id="motorsense_video"
              playsInline
              controls
              width="100%"
              height="720px"
            >
              <source
                src={require('../images/motorsense_pic/videoplayback (1).mp4')}
              />
            </video>
          </div>
        </div>
      </div>
    );
  };
}
