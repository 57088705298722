import React, { Component } from 'react';
import $ from 'jquery';
import Lightbox from 'react-lightbox-component';

class GlobalTrade extends Component {
  constructor(props) {
    super(props);

    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    this.state = {
      innolas_images: [
        {
          src: `/api1/static/global/innolas_1.webp`,
          title: langs.Global.inno_ttl_1,
          description: langs.Global.inno_desc_1
        },
        {
          src: `/api1/static/global/innolas_2.webp`,
          title: langs.Global.inno_ttl_2,
          description: langs.Global.inno_desc_2
        },
        {
          src: `/api1/static/global/innolas_3.webp`,
          title: langs.Global.inno_ttl_3,
          description: langs.Global.inno_desc_3
        }
      ],
      mazurczak_images: [
        {
          src: `/api1/static/global/mazurczak_1.webp`,
          title: langs.Global.maz_ttl_1
        },
        {
          src: `/api1/static/global/mazurczak_2.webp`,
          title: langs.Global.maz_ttl_2
        },
        {
          src: `/api1/static/global/mazurczak_3.webp`,
          title: langs.Global.maz_ttl_3
        }
      ],
      mbi_images: [
        {
          src: `/api1/static/global/mbi_1.webp`,
          title: langs.Global.mbi_ttl_1
        },
        {
          src: `/api1/static/global/mbi_2.webp`,
          title: langs.Global.mbi_ttl_2
        },
        {
          src: `/api1/static/global/mbi_3.webp`,
          title: langs.Global.mbi_ttl_3
        }
      ],
      war_img: [
        {
          src: `/api1/static/global/warwick_1.jpg`,
          title: langs.Global.war_ttl
        }
      ]
    };
  }

  componentDidMount() {
    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    $('#mbi_desc').html(langs.Global.mbi_desc);
    $('#mbi_ttl').html(langs.Global.mbi_ttl);
    $('#comp_prod').html(langs.Global.comp_prod);
    $('#globe_1_desc').html(langs.Home.globe_1_desc);
    $('#globe_2_desc').html(langs.Home.globe_2_desc);
    $('#globe_3_desc').html(langs.Home.globe_3_desc);

    $('.more').each(function() {
      $(this).html(langs.Buttons.more);
    });
    $('.comp_prod').each(function() {
      $(this).html(langs.Global.comp_prod);
    });
  }

  render = () => {
    return (
      <div>
        <div className="row" style={{ marginBottom: '100px' }}>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <a
                      href="https://www.innolas-solutions.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./inner_logo/innolas_logo.gif"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-left">
                <h4>InnoLas Solutions GmbH</h4>
                <p id="globe_1_desc">
                  InnoLas Solutions was founded in October 2013 as a demerger of
                  InnoLas PV and electronic business and looks back on over 20
                  years experience in laser technology.
                </p>
                <br />
                <a
                  href="https://www.innolas-solutions.com/innolas-solutions-laser-technology/"
                  target="_blank"
                  className="btn btn-warning mt-auto text-white btn-globe-more"
                  rel="noopener noreferrer"
                >
                  <span className="more">More</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-left">
                <h4 className="comp_prod">Company & Products</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-left">
                <Lightbox
                  images={this.state.innolas_images}
                  showImageModifiers={false}
                  renderImageFunc={(
                    idx,
                    image,
                    toggleLightbox,
                    width,
                    height
                  ) => {
                    return (
                      <img
                        key={idx}
                        src={image.src}
                        className="img-thumbnail img-prod-thumb"
                        style={{
                          cursor: 'pointer',
                          width: '180px',
                          height: '140px',
                          marginLeft: '5px',
                          marginRight: '5px'
                        }}
                        onClick={toggleLightbox.bind(null, idx)}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <a
                      href="https://www.mazurczak.de/en/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./inner_logo/mazurczak_logo.gif"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-left">
                <h4>Mazurczak GmbH</h4>
                <p id="globe_2_desc">
                  Our concentration on the needs of our customers in heating,
                  cooling and controlling of liquids has been an essential
                  element of our company’s philosophy since it was established
                  in 1935 by Wilhelm Mazurczak.
                </p>
                <a
                  href="https://www.mazurczak.de/en/Profil/About-Us"
                  target="_blank"
                  className="btn btn-warning mt-auto text-white btn-globe-more"
                  rel="noopener noreferrer"
                >
                  <span className="more">More</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-left">
                <h4 className="comp_prod">Company & Products</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-left">
                <Lightbox
                  images={this.state.mazurczak_images}
                  showImageModifiers={false}
                  renderImageFunc={(
                    idx,
                    image,
                    toggleLightbox,
                    width,
                    height
                  ) => {
                    return (
                      <img
                        key={idx}
                        src={image.src}
                        className="img-thumbnail img-prod-thumb"
                        style={{
                          cursor: 'pointer',
                          width: '180px',
                          height: '140px',
                          marginLeft: '5px',
                          marginRight: '5px'
                        }}
                        onClick={toggleLightbox.bind(null, idx)}
                      />
                    );
                  }}
                  renderDescriptionFunc={image => {
                    return null;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '100px' }}>
          <div className="col-lg-8 offset-lg-2">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <a
                      href="http://www.warwick-ins.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="./inner_logo/warwick_logo.gif"
                        className="img-fluid"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-left">
                <h4>WARWICK Instruments, Ltd.</h4>
                <p id="globe_3_desc">
                  WARWICK Instruments, Ltd. is a fast-growing provider of
                  broadest flow products in high-technology applications as
                  diverse as semiconductor, solar cell, flat panel, data
                  storage, glass, industrial coatings, and analytical
                  instrumentation, etc. WARWICK's product includes high quality
                  flow measurement & controller, gas process solution and global
                  support & service around the world. Leveraging our unsurpassed
                  technology in more advanced manufacturing application markets,
                  WARWICK devotes in maximizing provess potenncy and lowering
                  productivity cost for customers.
                </p>
                <a
                  href="http://www.warwick-ins.com/company-eg.html"
                  target="_blank"
                  className="btn btn-warning mt-auto text-white btn-globe-more"
                  rel="noopener noreferrer"
                >
                  <span className="more">More</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-left">
                <h4 className="comp_prod">Company & Products</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-left">
                <Lightbox
                  images={this.state.war_img}
                  showImageModifiers={false}
                  renderImageFunc={(
                    idx,
                    image,
                    toggleLightbox,
                    width,
                    height
                  ) => {
                    return (
                      <img
                        key={idx}
                        src={image.src}
                        className="img-thumbnail img-prod-thumb"
                        style={{
                          cursor: 'pointer',
                          width: '180px',
                          height: '140px',
                          marginLeft: '5px',
                          marginRight: '5px'
                        }}
                        onClick={toggleLightbox.bind(null, idx)}
                      />
                    );
                  }}
                  renderDescriptionFunc={image => {
                    return null;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '100px' }}>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <img
                      src={`/api1/static/global/mbi_main.webp`}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-left">
                <h4 id="mbi_ttl">MBI Clinic Centre</h4>
                <p id="mbi_desc">
                  Computerized Neurocognitive Test Assessment for improve mental
                  health. Visit for more details.
                </p>
                <a
                  href="https://www.mbi-clinic.center/"
                  target="_blank"
                  className="btn btn-warning mt-auto text-white btn-globe-more"
                  rel="noopener noreferrer"
                >
                  <span className="more">Visit</span>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Lightbox
                  images={this.state.mbi_images}
                  showImageModifiers={false}
                  renderImageFunc={(
                    idx,
                    image,
                    toggleLightbox,
                    width,
                    height
                  ) => {
                    return (
                      <img
                        key={idx}
                        src={image.src}
                        className="img-thumbnail img-prod-thumb"
                        style={{
                          cursor: 'pointer',
                          width: '190px',
                          height: '140px'
                        }}
                        onClick={toggleLightbox.bind(null, idx)}
                      />
                    );
                  }}
                  renderDescriptionFunc={image => {
                    return null;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default GlobalTrade;
