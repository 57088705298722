import Axios from 'axios';

class LangEngine {
  /**
   * Create Language Pulling object
   *
   * @param {String} lang_set
   * @param {String} lang_file
   * @param {Document} doc
   *
   */
  constructor(lang_set, lang_file, doc, f_name) {
    this.lang_file = lang_file;
    this.lang_arr = {};
    this.lang_set = lang_set;
    this.documents = doc;
    this.manipulated = false;
    this.documents.documentElement.lang = this.lang_set;
    this.File_Target = f_name;
  }

  /**
   * Manipulate the language file, verify the language file and convert
   * file into objects.
   * @param {(err:String, data:Object) => {}} callback
   *
   * @throws Error - Language Exception
   */
  Manipulate(callback) {
    if (this.lang_set == null)
      throw new Error('Language Exception: language unset in system.');
    else {
      Axios.get(`/api1/lang/${this.File_Target}`)
        .then(
          /**
           * @param {import('axios').AxiosResponse<String>} res
           *  */

          res => {
            var str = res.data;

            var arrs = str.split(/\r\n|\r|\n/);
            var tmps = {};
            var curr_inf = null;

            for (var i = 0; i < arrs.length; i++) {
              if (arrs[i].length <= 0) {
                continue;
              } else if (arrs[i].includes('[') && arrs[i].includes(']')) {
                var str = arrs[i].replace('[', '').replace(']', '');

                tmps[str] = {};
                curr_inf = str;
              } else if (arrs[i].includes('=')) {
                var sep = arrs[i].split(' = ');
                tmps[curr_inf][sep[0]] = sep[1];
              }
            }

            if (typeof tmps.Configuration === 'undefined') {
              callback('Language Exception: Invalid language file.', null);
            } else if (tmps.Configuration.apply_to !== 'sea_web') {
              callback(
                'Language Exception: Language file does not match this website.',
                null
              );
            } else if (tmps.Configuration.encoding !== 'utf-8') {
              callback('Language Exception: Invalid encoding format.', null);
            } else if (tmps.Configuration.short !== this.lang_set) {
              callback('Language Exception: Invalid language matching.', null);
            } else {
              this.lang_arr = tmps;
              callback(null, tmps);
            }
          }
        )
        .catch(err => {
          throw new Error(err);
        });
    }
  }
}

export default LangEngine;
