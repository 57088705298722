import React, { Component } from 'react';
import WebHeader from './base/head';
import WebFooter from './base/foot';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import './App.css';
import $ from 'jquery';
import { PacmanLoader } from 'react-spinners';
import { css } from '@emotion/react';

import Home from './component/Home';
import About from './component/About';
import Contact from './component/Contact';
import Business from './component/Business';
import Category from './component/Category';

import Cookie from './engine/cookie';
import LangEngine from './engine/lang_eng';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const queryString = require('query-string');

class App extends Component {
  constructor() {
    super();

    var sets = this.UpdateLanguage();

    this.state = {
      lang: sets.lg,
      full: sets.fl,
      render: false,
      isContact: false,
      currentRoute: '',
      currentParam: '',
      url_set: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.Scrolls();
    });

    this.Scrolls();

    setTimeout(
      function () {
        this.setState({ render: true });
      }.bind(this),
      2000
    );

    if (this.props.location.pathname == '/contact')
      this.setState({ isContact: true });

    this.setState({ currentRoute: this.props.location.pathname });
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevState.currentRoute !== this.props.location.pathname) {
      if (this.props.location.pathname == '/contact')
        this.setState({ isContact: true });
      else this.setState({ isContact: false });
    }

    if (prevState.currentParam !== this.state.currentParam) {
      this.setState({ url_set: this.state.currentParam });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location !== prevState.location) {
      var params = '';

      var currentRoute = nextProps.location.pathname;
      var query = queryString.parse(nextProps.location.search);

      if (Object.keys(query).length > 0) {
        params = query.item;
      }

      return { currentRoute: currentRoute, currentParam: params };
    }
  }

  Scrolls = () => {
    if (window.scrollY > window.innerHeight * 0.01) {
      $('.top_btn').css('visibility', 'visible');
      $('.top_btn').css('opacity', '1');
    }

    if (window.scrollY <= window.innerHeight * 0.01) {
      $('.top_btn').css('visibility', 'hidden');
      $('.top_btn').css('opacity', '0');
    }
  };

  BackTopClicked = (e) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  UpdateLanguage = () => {
    var cookie = new Cookie();
    var lg = '',
      fl = '',
      file = '';

    if (cookie.CheckParam('config') == true) {
      var config = JSON.parse(cookie.GetData('config'));
      lg = config.lang;
      fl = config.full;
      file = config.file;
    } else {
      cookie.NewData(
        'config',
        JSON.stringify({ lang: 'en', full: 'en_US', file: 'en_US.lang' })
      );
      lg = 'en';
      fl = 'en_US';
      file = 'en_US.lang';
    }

    try {
      this.lang = new LangEngine(lg, fl, document, file);
      this.lang.Manipulate((e, d) => {
        if (e) {
          throw new Error(e);
        } else {
          if (window.localStorage.hasOwnProperty('language_data'))
            window.localStorage.removeItem('language_data');
          window.localStorage.setItem('language_data', JSON.stringify(d));
        }
      });
    } catch (e) {}

    return { lg, fl };
  };

  componentWillUnmount() {
    var cookie = new Cookie();

    cookie.DeleteData('language_data');
  }

  render = () => {
    let renderCont = false;

    if (this.state.render) {
      renderCont = (
        <div>
          <WebHeader />
          <div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/business" component={Business} />
              <Route path="/product" component={Category} />
            </Switch>
          </div>
          <WebFooter />

          <button
            type="button"
            className="top_btn"
            data-toggle="tooltip"
            data-placement="left"
            title="Back to top"
            onClick={(e) => this.BackTopClicked(e)}
          >
            <i className="fas fa-caret-up" />
          </button>

          {this.state.isContact == false ? (
            <Link
              to={'/contact?r=' + this.state.url_set}
              className="contact_btn"
              data-toggle="tooltip"
              data-placement="left"
              title="Contact Us"
            >
              <i className="fas fa-phone" />
            </Link>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      renderCont = (
        <div className="container">
          <div style={{ marginTop: '40vh' }}>
            <div className="sweet-loading">
              <PacmanLoader css={override} loading={true} color={'pink'} />
            </div>
            <h4
              className="text-center"
              style={{ fontWeight: 600, marginTop: '40px' }}
            >
              SEA Website Is Loading
            </h4>
            <div className="text-center">
              Copyright &copy;, SEA Asia Pacific Sdn Bhd (1229114-T)
            </div>
          </div>
        </div>
      );
    }

    return renderCont;
  };
}

export default withRouter(App);
