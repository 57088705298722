import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './Category.css';
import $ from 'jquery';
import Axios from 'axios';
import GlobalTrade from './global_trade';
import MotorSense from './motor_sense';
import AutonomousRobot from './auto_robot';
import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser';

const queryString = require('query-string');

class Category extends Component {
  state = {
    product_category: '',
    products: [],
    prev_query: null,
    curr_query: null,
    initial_set: false,
    isOpen: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var query = queryString.parse(this.props.location.search);

    if (typeof query === 'undefined') {
      return null;
    } else {
      this.setState({ product_category: query.item });
    }

    if (
      query.item !== 'globaltrade_mbi' &&
      query.item !== 'motorsense' &&
      query.item !== 'autorobot'
    ) {
      if (this.state.initial_set == false) {
        Axios.get(`/api2/business_content/product_list?category=${query.item}`)
          .then((res) => {
            this.setState({
              prev_query: query.item,
              curr_query: query.item,
              products: res.data.list,
              initial_set: true,
            });
          })
          .catch((err) => {
            alert('Product retrieve error!');
          });
      }
    }
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    this.PageTitle(this.state.product_category);
    window.scrollTo(0, 0);

    if (
      this.state.product_category !== 'globaltrade_mbi' &&
      this.state.product_category !== 'motorsense' &&
      this.state.product_category !== 'autorobot'
    ) {
      if (this.state.initial_set == true) {
        if (this.state.prev_query !== this.state.curr_query) {
          Axios.get(
            `/api2/business_content/product_list?category=${this.state.curr_query}`
          )
            .then((res) => {
              this.setState({
                prev_query: this.state.curr_query,
                products: res.data.list,
              });
            })
            .catch((err) => {
              alert('Product retrieve error!');
            });
        }
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location !== prevState.location) {
      var query = queryString.parse(nextProps.location.search);

      return { product_category: query.item, curr_query: query.item };
    }
  }

  PageTitle = (title_name) => {
    var ttl_length = document.title.length;
    var cat_name = '';
    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    switch (title_name) {
      case 'photovoltaic':
        cat_name = langs.Titles.cat_1;
        break;

      case 'glass':
        cat_name = langs.Titles.cat_2;
        break;

      case 'spare_part':
        cat_name = langs.Titles.cat_3;
        break;

      case 'design':
        cat_name = langs.Titles.cat_4;
        break;

      case 'semiconductor':
        cat_name = langs.Titles.cat_5;
        break;

      case 'pcb':
        cat_name = langs.Titles.cat_6;
        break;

      case 'globaltrade_mbi':
        cat_name = langs.Titles.cat_7;
        break;

      case 'motorsense':
        cat_name = langs.Titles.cat_8;
        break;

      case 'autorobot':
        cat_name = langs.Titles.cat_9;
        break;

      default:
        return <Redirect to="/business" />;
    }
    if (ttl_length > 25) {
      var ttl = document.title.slice(0, 25);
      document.title = ttl + langs.Titles.product + ' (' + cat_name + ')';
    } else {
      document.title += langs.Titles.product + ' (' + cat_name + ')';
    }

    this.logo_input();
  };

  logo_input = () => {
    switch (this.state.product_category) {
      case 'photovoltaic':
        this.Prod_Logo.src = './inner_logo/photovoltaic_logo.webp';
        break;

      case 'glass':
        this.Prod_Logo.src = './inner_logo/glass_logo.webp';
        break;

      case 'spare_part':
        this.Prod_Logo.src = './inner_logo/sparepart_logo.png';
        break;

      case 'design':
        this.Prod_Logo.src = './inner_logo/design_logo.png';
        break;

      case 'semiconductor':
        this.Prod_Logo.src = './inner_logo/semiconductor_logo.png';
        break;

      case 'pcb':
        this.Prod_Logo.src = './inner_logo/pcb_logo.webp';
        break;

      case 'globaltrade_mbi':
        this.Prod_Logo.src = './inner_logo/globe_logo.webp';
        break;

      case 'motorsense':
        this.Prod_Logo.src = './inner_logo/motorsense.png';
        break;

      case 'autorobot':
        this.Prod_Logo.src = './inner_logo/robot.png';
        break;
    }
  };

  render = () => {
    const TitleClass = () => {
      var langs = JSON.parse(window.localStorage.getItem('language_data'));
      $('#category-bk-pic').removeClass();
      $('#category-bk-pic').addClass('pimg-category-main');

      switch (this.state.product_category) {
        case 'photovoltaic':
          $('#category-bk-pic').addClass('pimg-category-photovoltaic');
          return langs.Titles.cat_1;

        case 'glass':
          $('#category-bk-pic').addClass('pimg-category-glass');
          return langs.Titles.cat_2;

        case 'spare_part':
          $('#category-bk-pic').addClass('pimg-category-sparepart');
          return langs.Titles.cat_3;

        case 'design':
          $('#category-bk-pic').addClass('pimg-category-design');
          return langs.Titles.cat_4;

        case 'semiconductor':
          $('#category-bk-pic').addClass('pimg-category-semiconductor');
          return langs.Titles.cat_5;

        case 'pcb':
          $('#category-bk-pic').addClass('pimg-category-pcb');
          return langs.Titles.cat_6;

        case 'globaltrade_mbi':
          $('#category-bk-pic').addClass('pimg-category-globaltrade');
          return langs.Titles.cat_7;

        case 'motorsense':
          $('#category-bk-pic').addClass('pimg-category-motor');
          return 'AI Powered Motor Failure Prediction System';

        case 'autorobot':
          $('#category-bk-pic').addClass('pimg-category-robot');
          return 'Scorpion Series AMR Autonomous Mobile Robot';
      }
    };

    const CheckQuery = () => {
      var query = queryString.parse(this.props.location.search);

      if (typeof query.item === 'undefined') return <Redirect to="/business" />;
      else return null;
    };

    /** @param {any[]} arr */
    const img_arr = (arr) => {
      var statics = [];

      arr.map((val, ind) => {
        statics.push({
          src: `/api1/static/products/${val.image_name}`,
          title: val.thumbnail_title,
          description: val.thumbnail_details,
        });
      });

      return statics;
    };

    /**
     * @param {any[]} data
     * @returns {any[][]}
     */
    const feature_output = (data) => {
      var counts = 0;
      var write = [];
      var tmp = [];

      for (var i = 0; i < data.length; i++) {
        tmp.push(data[i]);
        counts++;

        if (counts > 1 || i == data.length - 1) {
          write.push(tmp);
          tmp = [];
          counts = 0;
        }
      }

      console.log(write);

      return write;
    };

    return (
      <div>
        {CheckQuery()}
        <div>
          <div id="business-section">
            <div id="category-bk-pic" className="">
              <div className="ptext-category-1 glass_override_semi_2">
                <div className="container">
                  <div className="row">
                    <div className="col-lg">
                      <div className="row">
                        <div className="col-lg">
                          <img
                            ref={(img) => (this.Prod_Logo = img)}
                            src=""
                            className="img-fluid"
                          />
                          {this.state.product_category == 'globaltrade_mbi' ? (
                            <img
                              src="./inner_logo/mbi_logo.webp"
                              className="img-fluid"
                              style={{ paddingLeft: '20px' }}
                            />
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: '20px' }}>
                        <div className="col-lg">
                          <span>{TitleClass()}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.product_category !== 'globaltrade_mbi' &&
            this.state.product_category !== 'motorsense' &&
            this.state.product_category !== 'autorobot' ? (
              this.state.products.length > 0 ? (
                <div className="product_lists">
                  {this.state.products.map((val, ind) => {
                    if (ind % 2 == 0) {
                      return (
                        <section
                          className="section section-business-one"
                          key={ind}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div
                                className="row"
                                style={{ marginBottom: '20px' }}
                              >
                                <div className="col-lg-12">
                                  <img
                                    src={`/api1/static/product_logo/${val[0][0].image_file}`}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="row rw2">
                                <div className="col-lg-12">
                                  {
                                    <Lightbox
                                      images={img_arr(val[2])}
                                      showImageModifiers={false}
                                      renderImageFunc={(
                                        idx,
                                        image,
                                        toggleLightbox,
                                        width,
                                        height
                                      ) => {
                                        return (
                                          <img
                                            key={idx}
                                            src={image.src}
                                            className="img-thumbnail img-prod-thumb"
                                            style={{
                                              cursor: 'pointer',
                                              width: '180px',
                                              height: '140px',
                                              marginLeft: '5px',
                                              marginRight: '5px',
                                            }}
                                            onClick={toggleLightbox.bind(
                                              null,
                                              idx
                                            )}
                                          />
                                        );
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 text-left">
                              <div
                                className="row"
                                style={{ marginBottom: '10px' }}
                              >
                                <div className="col-lg-12">
                                  <h3>{val[0][0].productName}</h3>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '50px' }}
                              >
                                <div className="col-lg-12 prod_desc">
                                  {ReactHtmlParser(val[0][0].productDesc)}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '10px' }}
                              >
                                <div className="col-lg-12">
                                  <h4>Features & Benefits</h4>
                                </div>
                              </div>
                              <div className="row feature_desc">
                                <div className="col-lg-12">
                                  {feature_output(val[1]).map((val, ind) => {
                                    return (
                                      <div className="row" key={ind}>
                                        <div className="col-xl-6">
                                          <div className="row">
                                            <div className="col-xl-2">
                                              <img
                                                src={`/api1/static/features/${val[0].feature_icon}`}
                                              />
                                            </div>
                                            <div className="col-xl-10 text-left ft_desc">
                                              <h6>{val[0].feature_title}</h6>
                                              {ReactHtmlParser(
                                                val[0].feature_desc
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xl-6">
                                          {val.length >= 2 ? (
                                            <div className="row">
                                              <div className="col-xl-2">
                                                <img
                                                  src={`/api1/static/features/${val[1].feature_icon}`}
                                                />
                                              </div>
                                              <div className="col-xl-10 text-left ft_desc">
                                                <h6>{val[1].feature_title}</h6>
                                                {ReactHtmlParser(
                                                  val[1].feature_desc
                                                )}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    } else {
                      return (
                        <section
                          className="section section-business-two"
                          key={ind}
                        >
                          <div className="row">
                            <div className="col-lg-6 text-left">
                              <div
                                className="row"
                                style={{ marginBottom: '10px' }}
                              >
                                <div className="col-lg-12">
                                  <h3>{val[0][0].productName}</h3>
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '50px' }}
                              >
                                <div className="col-lg-12 prod_desc">
                                  {ReactHtmlParser(val[0][0].productDesc)}
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ marginBottom: '10px' }}
                              >
                                <div className="col-lg-12">
                                  <h4>Features & Benefits</h4>
                                </div>
                              </div>
                              <div
                                className="row feature_desc"
                                style={{ color: 'black' }}
                              >
                                <div className="col-lg-12">
                                  {feature_output(val[1]).map((val, ind) => {
                                    return (
                                      <div className="row" key={ind}>
                                        <div className="col-xl-6">
                                          <div className="row">
                                            <div className="col-xl-2">
                                              <img
                                                src={`/api1/static/features/${val[0].feature_icon}`}
                                              />
                                            </div>
                                            <div className="col-xl-10 text-left ft_desc">
                                              <h6>{val[0].feature_title}</h6>
                                              {ReactHtmlParser(
                                                val[0].feature_desc
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-xl-6">
                                          {val.length >= 2 ? (
                                            <div className="row">
                                              <div className="col-xl-2">
                                                <img
                                                  src={`/api1/static/features/${val[1].feature_icon}`}
                                                />
                                              </div>
                                              <div className="col-xl-10 text-left ft_desc">
                                                <h6>{val[1].feature_title}</h6>
                                                {ReactHtmlParser(
                                                  val[1].feature_desc
                                                )}
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="row"
                                style={{ marginBottom: '20px' }}
                              >
                                <div className="col-lg-12">
                                  <img
                                    src={`/api1/static/product_logo/${val[0][0].image_file}`}
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  {
                                    <Lightbox
                                      images={img_arr(val[2])}
                                      showImageModifiers={false}
                                      renderImageFunc={(
                                        idx,
                                        image,
                                        toggleLightbox,
                                        width,
                                        height
                                      ) => {
                                        return (
                                          <img
                                            key={idx}
                                            src={image.src}
                                            className="img-thumbnail img-prod-thumb"
                                            style={{
                                              cursor: 'pointer',
                                              width: '180px',
                                              height: '140px',
                                              marginLeft: '5px',
                                              marginRight: '5px',
                                            }}
                                            onClick={toggleLightbox.bind(
                                              null,
                                              idx
                                            )}
                                          />
                                        );
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    }
                  })}
                </div>
              ) : (
                <section className="section section-business-one">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src={require('../images/coming-soon.png')}
                          className="img-fluid"
                          width="40%"
                        />
                      </div>
                      <div className="col-md-6" style={{ margin: 'auto' }}>
                        <h3 className="text-white">
                          We will update our product soon.
                        </h3>
                      </div>
                    </div>
                  </div>
                </section>
              )
            ) : (
              <div>
                {this.state.product_category == 'globaltrade_mbi' ? (
                  <section className="section section-business-two">
                    <GlobalTrade />
                  </section>
                ) : this.state.product_category == 'motorsense' ? (
                  <div className="container-fluid">
                    <MotorSense />
                  </div>
                ) : this.state.product_category == 'autorobot' ? (
                  <div className="container-fluid">
                    <AutonomousRobot />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default Category;
