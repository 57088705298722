class Cookie {
  constructor() {}

  CheckParam = param => {
    return localStorage.hasOwnProperty(param);
  };

  GetData = param => {
    if (localStorage.hasOwnProperty(param)) {
      return localStorage.getItem(param);
    } else {
      return null;
    }
  };

  NewData = (param, data) => {
    if (this.empty(param) == false && this.empty(data) == false) {
      if (!localStorage.hasOwnProperty(param)) {
        localStorage.setItem(param, data);
      } else {
        throw new Error('Add cookie error, key pairs exists.');
      }
    } else {
      throw new Error('Add cookie error, empty required parameters.');
    }
  };

  UpdateData = (param, new_data) => {
    if (this.empty(param) == false && this.empty(new_data) == false) {
      if (localStorage.hasOwnProperty(param)) {
        localStorage.setItem(param, new_data);
      } else {
        throw new Error('Edit cookie error, key pairs not exists.');
      }
    } else {
      throw new Error('Edit cookie error, empty required parameters.');
    }
  };

  DeleteData = param => {
    if (localStorage.hasOwnProperty(param)) {
      localStorage.removeItem(param);
    } else {
      throw new Error('Delete cookie error, key pairs not exists.');
    }
  };

  empty = str => {
    switch (str) {
      case '':
      case 0:
      case '0':
      case null:
      case false:
      case typeof this == 'undefined':
        return true;
      default:
        return false;
    }
  };
}

export default Cookie;
