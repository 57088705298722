import React, { Component } from 'react';
import './head.css';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import Cookie from '../engine/cookie';
import Axios from 'axios';
var jquery = require('jquery');
require('smartmenus');
require('smartmenus-bootstrap-4');

class WebHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang_available: [],
      prod_main: null,
    };
  }

  componentWillMount() {}

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.Scrolls();
    });

    jquery(() => {
      jquery('#main_menu_item').smartmenus({ showOnClick: true });
    });

    this.Scrolls();

    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    for (var keys in langs.Titles) {
      $(`#${keys}`).html(langs.Titles[keys]);
    }

    Axios.get(`/api1/lang_mgr/lang_list`)
      .then((res) => {
        this.setState({ lang_available: res.data });
        this.SelectedLang.innerHTML = langs.Configuration.lang_name;
      })
      .catch((err) => {});
  }

  Scrolls = () => {
    if (
      window.scrollY > window.innerHeight * 0.01 &&
      window.innerWidth >= 769
    ) {
      $('#main_navbar').css('background-color', 'rgba(237, 141, 7, 0.6)');
      $('.nav-link').css('color', 'rgb(255, 255, 255)');
    }

    if (
      window.scrollY <= window.innerHeight * 0.01 &&
      window.innerWidth >= 769
    ) {
      $('#main_navbar').css('background-color', 'transparent');
      $('.nav-link').css('color', 'rgb(239, 11, 95)');
    }
  };

  UpdateLang = (e, lang, full) => {
    var cookie = new Cookie();
    var tg_file = null;

    for (var i = 0; i < this.state.lang_available.length; i++) {
      if (this.state.lang_available[i].code == full) {
        tg_file = this.state.lang_available[i].file_name;
      }
    }

    cookie.UpdateData(
      'config',
      JSON.stringify({ lang: lang, full: full, file: tg_file })
    );

    window.location.href = '/';
  };

  /**
   * @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} e
   */
  ProdClick = (e) => {
    this.setState({ prod_main: <Redirect to="/business" /> });
    setTimeout(() => {
      this.setState({ prod_main: null });
    }, 500);
  };

  render = () => {
    return (
      <div>
        {this.state.prod_main}
        <nav
          className="navbar navbar-expand-md navbar-dark fixed-top"
          style={{ backgroundColor: 'rgba(237, 141, 7, 0)' }}
          id="main_navbar"
          role="navigation"
        >
          <div className="container-fluid">
            <Link
              to="/"
              className="navbar-brand"
              style={{ width: '50%', height: '50%' }}
            >
              <img src="/logo.png" width="100%" height="100%" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#menu_collp"
            >
              <span className="navbar-toggler-icon" />
            </button>

            <div className="collapse navbar-collapse" id="menu_collp">
              <ul className="nav navbar-nav ml-auto sm" id="main_menu_item">
                <li className="nav-item">
                  <Link to="/" id="nav_home_link" className="nav-link">
                    <span id="main" />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" id="nav_about_link" className="nav-link">
                    <span id="about" />
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/business"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    onDoubleClick={(e) => this.ProdClick(e)}
                  >
                    <span id="business" />
                    <span className="caret" />
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        to="/product?item=photovoltaic"
                        className="dropdown-item"
                      >
                        <span id="cat_1" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/product?item=glass" className="dropdown-item">
                        <span id="cat_2" />
                      </Link>
                    </li>

                    <li>
                      <Link to="/product?item=pcb" className="dropdown-item">
                        <span id="cat_6" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product?item=motorsense"
                        className="dropdown-item"
                      >
                        <span id="">Motor Sense</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product?item=autorobot"
                        className="dropdown-item"
                      >
                        <span id="">Autonomous Robot</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product?item=spare_part"
                        className="dropdown-item"
                      >
                        <span id="cat_3" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/product?item=design" className="dropdown-item">
                        <span id="cat_4" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product?item=semiconductor"
                        className="dropdown-item"
                      >
                        <span id="cat_5" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/product?item=globaltrade_mbi"
                        className="dropdown-item"
                      >
                        <span id="cat_7" />
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    to="/contact"
                    id="nav_contact_link"
                    className="nav-link"
                  >
                    <span id="contact" />
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="#"
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <span ref={(e) => (this.SelectedLang = e)} />{' '}
                    <span className="caret" />
                  </Link>
                  <ul className="dropdown-menu">
                    {this.state.lang_available.map((val, ind) => {
                      return (
                        <li key={ind}>
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={(e) =>
                              this.UpdateLang(e, val.short, val.code)
                            }
                          >
                            {val.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="phone_comp_1" />
      </div>
    );
  };
}

export default WebHeader;
