import React, { Component } from 'react';
import './About.css';
import '../engine/Timeline.scss';
import Axios from 'axios';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser';
import $ from 'jquery';

class About extends Component {
  constructor() {
    super();
    this.state = {
      timeline_tmp: [],
      global_tmp: []
    };
  }

  componentDidMount() {
    var ttl_length = document.title.length;

    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    for (var keys in langs.About) {
      $(`#${keys}`).html(langs.About[keys]);
    }

    var more_btn_word = langs.Buttons.more;
    this.AboutTtl.innerHTML = langs.Titles.about;

    $('.more_btn').each(function() {
      $(this).html(more_btn_word);
    });

    if (ttl_length > 25) {
      var ttl = document.title.slice(0, 25);
      document.title = ttl + langs.Titles.about;
    } else {
      document.title += langs.Titles.about;
    }

    window.scrollTo(0, 0);

    this.GetTimelineData();
  }

  componentWillUnmount() {
    $('#pin-1').popover('hide');
  }

  GetTimelineData = () => {
    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    var timelines = langs.Timeline;
    var tmp = [];

    for (var i = 0; i < 6; i++) {
      tmp.push({
        year: timelines[`time_${i + 1}_y`],
        description: timelines[`time_${i + 1}_d`]
      });
    }

    this.setState({ timeline_tmp: tmp });

    Axios.get(`/api2/about_content/globalitem`)
      .then(res => {
        this.setState({ global_tmp: res.data.response });
        $('#pin-1').popover('show');
      })
      .catch(err => {
        console.log(err);
      });

    Axios.get(`/api1/file_mgr/get_about_vid_default`)
      .then(res => {
        this.AboutVid.src = res.data.defaults;
      })
      .catch(err => {});
  };

  MapPopOver = (e, id) => {
    $('#pin-1').popover('hide');
    $('#' + id).popover('show');
  };

  MapPopOverOut = (e, id) => {
    $('#' + id).popover('hide');
  };

  PinAnimateInjectCss = (selector, key, left, bottom) => {
    var css_elems = document.getElementById('CssInjector');

    css_elems.innerHTML +=
      '<style>' +
      '.' +
      selector +
      ':hover{' +
      'animation-name: ani-' +
      key +
      ';' +
      'animation-duration: 1s;' +
      'animation-iteration-count: infinite;' +
      'animation-timing-function: linear;' +
      '}' +
      '@keyframes ani-' +
      key +
      ' {' +
      '0% { bottom: ' +
      parseFloat(bottom) +
      '%; }' +
      '50% { bottom: ' +
      (parseFloat(bottom) + 3) +
      '%; }' +
      ' {' +
      '100% { bottom: ' +
      parseFloat(bottom) +
      '%; }' +
      '}' +
      '</style>';
  };

  render = () => {
    return (
      <div>
        <div id="about-section">
          <div className="pimg-about-1">
            <div className="ptext-about-1 glass_override_semi">
              <span
                ref={e => {
                  this.AboutTtl = e;
                }}
              >
                About Us
              </span>
            </div>
          </div>

          <section className="section section-about-one section-about-comp">
            <div className="container">
              <div className="row">
                <div className="col-xl-5">
                  <video
                    id="about_video"
                    className="about_video"
                    playsInline
                    controls
                    ref={c => (this.AboutVid = c)}
                  />
                </div>
                <div className="col-xl-7 about-brief">
                  <h4>SEA Asia Pacific (M) Sdn Bhd </h4>
                  <p style={{ textAlign: 'justify' }} id="row_1" />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="about-row-1-section">
          <section className="section section-about-two">
            <div className="container-fluid sec-two-card">
              <div className="row">
                <div className="col-lg">
                  <div className="card">
                    <div className="card-body">
                      <img src="./inner_logo/business_unit_icon.webp" />
                      <h4 className="about-row-1-ttl" id="row_2_card_1_ttl">
                        Business unit
                      </h4>
                      <div className="about-elems-line" />
                      <p
                        className="about-row-1-content"
                        id="row_2_card_1_desc"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg">
                  <div className="card">
                    <div className="card-body">
                      <img src="./inner_logo/employee_icon.webp" />
                      <h4 className="about-row-1-ttl" id="row_2_card_2_ttl">
                        Employees
                      </h4>
                      <div className="about-elems-line" />
                      <p
                        className="about-row-1-content"
                        id="row_2_card_2_desc"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg">
                  <div className="card">
                    <div className="card-body">
                      <img src="./inner_logo/partner_icon.webp" />
                      <h4 className="about-row-1-ttl" id="row_2_card_3_ttl">
                        Partners
                      </h4>
                      <div className="about-elems-line" />
                      <p
                        className="about-row-1-content"
                        id="row_2_card_3_desc"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="about-row-2-section">
          <section className="section section-about-three bk_ride">
            <div className="section-about-three-container">
              <h3 className="history_ttl" id="row_3_ttl">
                History
              </h3>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div id="timeline">
                      {this.state.timeline_tmp.map((val, index) => {
                        return (
                          <div className="timeline-item" key={index}>
                            <div className="timeline-icon">
                              <div className="dates">{val.year}</div>
                            </div>
                            <div
                              className={`timeline-content ${
                                index % 2 == 0 ? '' : 'right'
                              }`}
                            >
                              {ReactHtmlParser(val.description)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="about-row-3-section">
          <section className="section section-about-four">
            <h3 className="history_ttl" id="row_4_ttl">
              Global Presence
            </h3>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="global-map-container">
                    <div className="global-map-empty-map">
                      <img src="../inner_logo/map_empty.png" />
                    </div>
                    <div className="global-map-pins">
                      {this.state.global_tmp.map((val, ind) => {
                        var url = '';
                        var size = { w: '3', h: '10' };

                        switch (val.pin_type) {
                          case 1:
                            url = '../inner_logo/red_pin.png';
                            size = { w: '3', h: '10' };
                            break;

                          case 2:
                            url = '../inner_logo/blue_pin.png';
                            size = { w: '3', h: '10' };
                            break;

                          case 3:
                            url = '../inner_logo/black_pin.png';
                            size = { w: '2', h: '6' };
                            break;
                        }

                        var selector = 'pins-' + ind;

                        this.PinAnimateInjectCss(
                          selector,
                          ind,
                          val.set_left,
                          val.set_bottom
                        );

                        return (
                          <img
                            key={ind}
                            src={url}
                            className={'map-pin ' + selector}
                            style={{
                              width: size.w + '%',
                              height: size.h + '%',
                              left: val.set_left + '%',
                              bottom: val.set_bottom + '%'
                            }}
                            data-toggle="popover"
                            title={val.pop_title}
                            data-content={val.pop_content}
                            data-placement="top"
                            data-html="true"
                            id={'pin-' + ind}
                            onMouseOver={e => {
                              this.MapPopOver(e, 'pin-' + ind);
                            }}
                            onMouseOut={e => {
                              this.MapPopOverOut(e, 'pin-' + ind);
                            }}
                          />
                        );
                      })}
                    </div>
                    <div id="CssInjector" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="about-row-4-section">
          <section className="section section-about-five">
            <h3 className="history_ttl" id="row_5_ttl">
              Organization
            </h3>
            <div className="container">
              <img
                src="../inner_logo/about_organization.webp"
                className="organization_photo"
              />
            </div>
          </section>
        </div>
      </div>
    );
  };
}

export default About;
