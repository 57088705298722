import React, { Component } from 'react';
import './foot.css';
import Axios from 'axios';

class WebFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      addr1: null,
      addr2: null,
      city: null,
      post: null,
      states: null,
      country: null,
      tel: null,
      roc: null,
      fax: null,
      mail: null,
      curYear: ""
    };
  }

  componentDidMount() {
    Axios.get(`/api1/company_prof`)
      .then(res => {
        var d = res.data;
        var stateSet = [
          'name',
          'addr1',
          'addr2',
          'city',
          'post',
          'states',
          'country',
          'tel',
          'roc',
          'fax',
          'mail'
        ];

        var recog = [
          'company_name',
          'company_address_1',
          'company_address_2',
          'company_city',
          'company_post_code',
          'company_state',
          'company_country',
          'company_tel',
          'company_roc',
          'company_fax',
          'company_mail'
        ];

        var newState = {};

        for (var i = 0; i < d.length; i++) {
          for (var j = 0; j < recog.length; j++) {
            if (d[i].prof_name == recog[j]) {
              newState[stateSet[j]] = d[i].prof_value;
              break;
            }
          }
        }

        var today = new Date();
        var curYear = today.getFullYear();
        newState["curYear"] = curYear;
        this.setState(newState);
      })
      .catch(err => {
        alert('Unable to get company profiles.');
      });
  }

  render = () => {
    return (
      <div>
        <footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
		<div className="foot_img_cont">
		   <img src="./inner_logo/ias_logo.png" className="logo_3" />
		   <img src="./logo_2.png" className="logo_2" />
		</div>
              </div>
              <div className="col-md-7 my-auto">
                <span>
                  ({this.state.name}, {this.state.roc}) {this.state.addr1},{' '}
                  {this.state.addr2}, {this.state.post} {this.state.city},{' '}
                  {this.state.states}, {this.state.country}
                </span>
                <br />
                <span>
                  Tel: {this.state.tel} / Fax: {this.state.fax} / E-mail:{' '}
                  <a href={`mailto:${this.state.mail}`}>{this.state.mail}</a>
                </span>
                <br />
                <span>
                  {this.state.curYear} Copyright &copy; SEA (Malaysia), ALL RIGHT RESERVED.
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  };
}

export default WebFooter;
