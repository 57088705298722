import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Business.css';
import $ from 'jquery';

class Business extends Component {
  componentDidMount() {
    var ttl_length = document.title.length;

    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    for (var keys in langs.Titles) {
      if (keys.includes('cat_'))
        $(`#b${keys}`).html(langs.Titles[keys] + '&nbsp;');
    }

    this.BusinessTtl.innerHTML = langs.Titles.business;

    if (ttl_length > 25) {
      var ttl = document.title.slice(0, 25);
      document.title = ttl + langs.Titles.business;
    } else {
      document.title += langs.Titles.business;
    }
  }

  render = () => {
    return (
      <div>
        <div id="business-section">
          <div className="pimg-business-1">
            <div className="ptext-business-1 glass_override_semi_2">
              <span ref={(e) => (this.BusinessTtl = e)}>Our Business</span>
            </div>
          </div>

          <section className="section section-business-one no_sides">
            <div className="container">
              <Link to="/product?item=photovoltaic" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/photovoltaic_logo.webp" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_1">Photovoltaic </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/product?item=glass" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/glass_logo.webp" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_2">Glass </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/product?item=pcb" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/pcb_logo.webp" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_6">PCB </span>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/product?item=motorsense" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/motorsense.png" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_6">Motor Sense </span>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to="/product?item=autorobot" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/robot.png" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_6">Autonomous Robot </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/product?item=spare_part" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/sparepart_logo.png" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_3">Spare Part </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/product?item=design" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/design_logo.png" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_4">Design </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/product?item=semiconductor" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/semiconductor_logo.png" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_5">Semiconductor </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/product?item=globaltrade_mbi" className="btn_business">
                <div className="row">
                  <div className="col">
                    <div className="table-cell-1">
                      <img src="./inner_logo/globe_logo.webp" />
                    </div>
                    <div className="table-cell-2">
                      <span id="bcat_7">Global Trading & MBI </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </section>
        </div>
      </div>
    );
  };
}

export default Business;
