import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import './Contact.css';
import $ from 'jquery';
import Axios from 'axios';

const GmAPI = require('load-google-maps-api');
const queryString = require('query-string');

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      addr1: null,
      addr2: null,
      city: null,
      post: null,
      states: null,
      country: null,
      tel: null,
      roc: null,
      fax: null,
      mail: null,
      preText: '',
    };
  }

  componentDidMount() {
    var ttl_length = document.title.length;

    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    for (var keys in langs.Contact) {
      if (keys.includes('_inp')) {
        $(`#${keys}`).attr('placeholder', langs.Contact[keys]);
      } else $(`#${keys}`).html(langs.Contact[keys]);
    }

    var btn_word = langs.Buttons;
    this.ContactTtl.innerHTML = langs.Titles.contact;

    for (var keys in btn_word) {
      $(`#${keys}`).html(btn_word[keys]);
    }

    if (ttl_length > 25) {
      var ttl = document.title.slice(0, 25);
      document.title = ttl + langs.Titles.contact;
    } else {
      document.title += langs.Titles.contact;
    }

    window.scrollTo(0, 0);
    Axios.get(`/api1/company_prof`)
      .then((res) => {
        var d = res.data;
        var stateSet = [
          'name',
          'addr1',
          'addr2',
          'city',
          'post',
          'states',
          'country',
          'tel',
          'roc',
          'fax',
          'mail',
        ];

        var recog = [
          'company_name',
          'company_address_1',
          'company_address_2',
          'company_city',
          'company_post_code',
          'company_state',
          'company_country',
          'company_tel',
          'company_roc',
          'company_fax',
          'company_mail',
        ];

        var newState = {};

        for (var i = 0; i < d.length; i++) {
          for (var j = 0; j < recog.length; j++) {
            if (d[i].prof_name == recog[j]) {
              newState[stateSet[j]] = d[i].prof_value;
              break;
            }
          }
        }

        this.setState(newState);
      })
      .catch((err) => {
        alert('Unable to get company profiles.');
      });

    this.PullParam();
    this.LoadGoogleMaps();
  }

  PullParam = () => {
    var query = queryString.parse(this.props.location.search);

    if (Object.keys(query).length > 0) {
      var params = query.r;

      switch (params) {
        case 'photovoltaic':
          this.setState({ preText: 'Inquiry for Photovoltaic product' });
          break;

        case 'glass':
          this.setState({ preText: 'Inquiry for Glass product' });
          break;

        case 'spare_part':
          this.setState({ preText: 'Inquiry for Spare Parts product' });
          break;

        case 'design':
          this.setState({ preText: 'Inquiry for Design product' });
          break;

        case 'semiconductor':
          this.setState({ preText: 'Inquiry for Semiconductor product' });
          break;

        case 'pcb':
          this.setState({ preText: 'Inquiry for PCB product' });
          break;

        case 'globaltrade_mbi':
          this.setState({
            preText: 'Inquiry for Global Trade & MBI Clinic',
          });
          break;

        case 'motorsense':
          this.setState({ preText: 'Inquiry for Motorsense product' });
          break;

        case 'autorobot':
          this.setState({ preText: 'Inquiry for Autonomous Robot product' });
          break;
      }
    }
  };

  OpenMessageModal = (args) => {
    $('#MessageModal').modal();
    $('#msg_area').val(this.MsgTxt.value);
  };

  SaveMsgCopy = (e, msg) => {
    this.MsgTxt.value = msg;
  };

  LoadGoogleMaps = () => {
    GmAPI({ key: 'AIzaSyCm2vAxNfQZGZGjU4s0yvWmdNtytDM0eB0' })
      .then((googleMaps) => {
        var selector = document.getElementById('gm-maps');
        var exactLocation = new googleMaps.LatLng(5.3147727, 100.4751566);
        var params = {
          center: exactLocation,
          zoom: 18,
          size: '1920x1080',
        };

        var maps = new googleMaps.Map(selector, params);
        var marker = new googleMaps.Marker({ position: exactLocation });
        var info = new googleMaps.InfoWindow({
          content: this.MarkerContent(),
        });

        marker.setMap(maps);
        marker.addListener('click', () => {
          info.open(maps, marker);
        });

        info.open(maps, marker);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  MarkerContent = () => {
    return ReactDOMServer.renderToStaticMarkup(
      <div id="content">
        <div id="siteNotice" />
        <h6 id="firstHeading" className="firstHeading">
          SEA Asia Pacific Sdn Bhd
        </h6>
        <div id="bodyContent">
          <address style={{ fontWeight: 'normal', textAlign: 'left' }}>
            No.2A, Lorong Industri Impian 1
            <br />
            Taman Industri Impian
            <br />
            14000 Bukit Mertajam
            <br />
            Pulau Pinang, <span style={{ fontWeight: 'bold' }}>Malaysia</span>.
            <br />
            <br />
            <a
              href="https://www.google.com/maps/place/5.3147727+100.4751566/@5.3147727,100.4751566,16z"
              target="_blank"
              rel="noopener noreferrer"
            >
              Latitude: 5.3147727, Longitude: 100.4751566
            </a>
          </address>
          <img src="./logo_2.png" />
        </div>
      </div>
    );
  };

  FormSubmit = () => {
    var fname = this.fName;
    var email = this.Email;
    var telNo = this.TelNo;
    var titles = this.Titles;
    var msgs = this.MsgTxt;
    var isValid = true;
    var tgFirst = null;

    if (fname.value.length <= 4) {
      isValid = false;
      tgFirst = fname;
    }

    if (!email.value.includes('@') || !email.value.includes('.')) {
      isValid = false;
      if (tgFirst == null) tgFirst = email;
    }

    if (titles.value.length <= 6) {
      isValid = false;
      if (tgFirst == null) tgFirst = titles;
    }

    if (isValid) {
      if (window.confirm('Are you sure is completely write?')) {
        Axios.post(`/api2/send_mail`, {
          full_name: fname.value,
          email: email.value,
          ttl: titles.value,
          msg: msgs.value,
          phone: telNo.value,
        })
          .then((res) => {
            if (res.data.result) {
              alert('Your enquiries had been sent!');
              this.ContactForm.reset();
            } else {
              alert('Enquiries unsend!');
            }
          })
          .catch((err) => {
            alert('Server error!');
          });
      }
    } else {
      alert('Invalid input, please check again!');
      tgFirst.focus();
    }
  };

  render = () => {
    return (
      <div>
        <div id="contact-session">
          <div className="pimg-contact-1">
            <div className="ptext-contact-1">
              <span ref={(e) => (this.ContactTtl = e)}>Contact Us</span>
            </div>
          </div>

          <section className="section section-contact-one">
            <div className="container">
              <div className="row">
                <div className="col-lg">
                  <div className="card">
                    <div className="card-body text-alignment-left">
                      <i className="far fa-building contact_title" /> &nbsp;
                      <span className="contact_title" id="addr">
                        Address
                      </span>
                      <br />
                      <br />
                      <address>
                        {this.state.name} ({this.state.roc})
                        <br />
                        {this.state.addr1}
                        <br />
                        {this.state.addr2}
                        <br />
                        {this.state.post} {this.state.city}
                        <br />
                        {this.state.states},{' '}
                        <span style={{ fontWeight: 'bold' }}>
                          {this.state.country}
                        </span>
                        .
                      </address>
                      <br />
                      <i className="far fa-envelope contact_title" /> &nbsp;
                      <span className="contact_title" id="email">
                        Email
                      </span>
                      <br />
                      <br />
                      <address>
                        <a href={`mailto:${this.state.mail}`}>
                          {this.state.mail}
                        </a>
                      </address>
                      <br />
                      <address>
                        <i className="fas fa-phone contact_title" />{' '}
                        {this.state.tel}&nbsp;&nbsp;/&nbsp;&nbsp;
                        <i className="fas fa-fax contact_title" />{' '}
                        {this.state.fax}
                      </address>
                    </div>
                  </div>
                </div>
                <div className="col-lg">
                  <div className="card">
                    <div className="card-body text-alignment-left">
                      <i className="far fa-envelope-open contact_title" />{' '}
                      &nbsp;
                      <span className="contact_title" id="reqs">
                        Request Information
                      </span>
                      <br />
                      <br />
                      <form
                        autoComplete="false"
                        ref={(e) => (this.ContactForm = e)}
                      >
                        <input
                          type="text"
                          maxLength="100"
                          placeholder="Your Name *"
                          id="name_inp"
                          className="contact_input"
                          ref={(e) => (this.fName = e)}
                        />
                        <br />
                        <input
                          type="email"
                          maxLength="100"
                          id="email_inp"
                          placeholder="Your Email Address *"
                          className="contact_input"
                          ref={(e) => (this.Email = e)}
                        />
                        <br />
                        <input
                          type="tel"
                          placeholder="Your Contact No"
                          id="tel_inp"
                          className="contact_input"
                          ref={(e) => (this.TelNo = e)}
                        />
                        <br />
                        <input
                          type="text"
                          maxLength="200"
                          placeholder="Title *"
                          id="title_inp"
                          className="contact_input"
                          defaultValue={this.state.preText}
                          ref={(e) => (this.Titles = e)}
                        />
                        <br />
                        <textarea
                          rows="3"
                          className="contact_textarea"
                          readOnly
                          ref={(msgtxt) => (this.MsgTxt = msgtxt)}
                          onClick={(e) => {
                            this.OpenMessageModal(e);
                          }}
                          placeholder="Your Message...."
                          id="msg_inp"
                        />
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-warning"
                          style={{ float: 'right' }}
                          onClick={(e) => this.FormSubmit(e)}
                        >
                          <span id="send">Send</span>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="MapSession">
          <section className="section" style={{ padding: 0 }}>
            <div id="gm-maps" style={{ width: '100%', height: '500px' }} />
          </section>
        </div>

        <MessageModal OnSaveMsg={(e, msg) => this.SaveMsgCopy(e, msg)} />
      </div>
    );
  };
}

class MessageModal extends Component {
  MessageInputWordCheck = (e) => {
    var WordCount = this.MsgArea.value.length;

    this.WCount.innerHTML = 500 - WordCount;
  };

  ResetMsgBox = (e) => {
    this.MsgArea.value = '';

    this.MessageInputWordCheck(null);
  };

  SaveMsgBox = (e) => {
    this.props.OnSaveMsg(e, this.MsgArea.value);
  };

  render = () => {
    return (
      <div>
        <div id="MessageModal" className="modal fade" role="dialog">
          <div className="modal-dialog modal-lg">
            <div
              className="modal-content"
              style={{
                backgroundColor: 'rgba(2, 238, 255, 0.8)',
                border: 'none',
                boxShadow: '5px 10px 18px #888888',
              }}
            >
              <div
                className="modal-header"
                style={{ borderBottom: '2px outset #0117ff' }}
              >
                <h4 className="modal-title" id="msg_ttl">
                  Message
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  ×
                </button>
              </div>
              <div className="modal-body">
                <textarea
                  rows="10"
                  style={{
                    width: '100%',
                    outline: 'none',
                    borderRadius: '10px',
                    resize: 'none',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    overflowY: 'scroll',
                  }}
                  ref={(msg) => (this.MsgArea = msg)}
                  id="msg_area"
                  onInput={(e) => {
                    this.MessageInputWordCheck(e);
                  }}
                  maxLength="500"
                />
                <br />
                <span style={{ float: 'right' }}>
                  <strong ref={(w_ct) => (this.WCount = w_ct)}>500</strong>{' '}
                  word(s) left.
                </span>
              </div>
              <div
                className="modal-footer"
                style={{ borderTop: '2px solid #0117ff' }}
              >
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                  onClick={(e) => {
                    this.SaveMsgBox(e);
                  }}
                >
                  <span id="save">Save</span>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-info"
                  onClick={(e) => this.ResetMsgBox(e)}
                >
                  <span id="res">Reset</span>
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  data-dismiss="modal"
                >
                  <span id="close">Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Contact;
