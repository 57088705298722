import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import $ from 'jquery';
import Axios from 'axios';
import ScrollMenu from 'react-horizontal-scrolling-menu';

const data = [
  {
    link: 'photovoltaic',
    logo: 'photovoltaic_logo.webp',
    id: 'provide_cont_1',
  },
  { link: 'glass', logo: 'glass_logo.webp', id: 'provide_cont_2' },
  { link: 'pcb', logo: 'pcb_logo.webp', id: 'provide_cont_3' },
  { link: 'motorsense', logo: 'motorsense.png', id: 'provide_cont_4' },
  { link: 'autorobot', logo: 'robot.png', id: 'provide_cont_5' },
  {
    link: 'globaltrade_mbi',
    logo: 'globe_logo.webp',
    id: 'provide_cont_6',
  },
  {
    link: 'globaltrade_mbi',
    logo: 'mbi_logo.webp',
    id: 'provide_cont_7',
  },
];

const MenuItem = ({ link, logo, id }) => {
  return (
    <div className="menu-item">
      <div className="card">
        <div className="card-body">
          <Link to={`/product?item=${link}`}>
            <img
              src={`./inner_logo/${logo}`}
              className="business-logo"
              alt="logo"
            />
          </Link>
          <br />
          <br />
          <h4 className="card-title" id={id + '_ttl'}></h4>
          <p className="card-text" id={id + '_desc'}></p>
        </div>
      </div>
    </div>
  );
};

export const Menu = (list) =>
  list.map((el, ind) => {
    const { link, logo, id } = el;

    return <MenuItem link={link} logo={logo} id={id} key={ind} />;
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: <img src={require('../images/arrow-left.svg')} />,
  className: 'arrow-prev',
});
const ArrowRight = Arrow({
  text: <img src={require('../images/arrow-right.svg')} />,
  className: 'arrow-next',
});

class Home extends Component {
  constructor() {
    super();

    this.state = {
      gallery_file: [],
    };

    this.slidemenu = Menu(data);
  }

  componentDidMount() {
    var ttl_length = document.title.length;

    var langs = JSON.parse(window.localStorage.getItem('language_data'));

    for (var keys in langs.Home) {
      $(`#${keys}`).html(langs.Home[keys]);
    }

    var more_btn_word = langs.Buttons.more;

    $('.more_btn').each(function () {
      $(this).html(more_btn_word);
    });

    if (ttl_length > 25) {
      var ttl = document.title.slice(0, 25);
      document.title = ttl + langs.Configuration.main_ttl;
    } else {
      document.title += langs.Configuration.main_ttl;
    }

    window.scrollTo(0, 0);

    window.addEventListener('scroll', () => {
      this.Scrolls();
    });

    this.Scrolls();
    this.GetDefaults();
  }

  Scrolls = () => {
    if (window.scrollY > 1320 && window.innerWidth >= 480) {
      $('.prod_animate').css('visibility', 'visible');
      $('.prod_animate').css('opacity', '1');
    }

    if (window.scrollY > 520 && window.innerWidth < 480) {
      $('.prod_animate').css('visibility', 'visible');
      $('.prod_animate').css('opacity', '1');
    }
  };

  GetDefaults = () => {
    Axios.get(`/api1/file_mgr/get_main_vid_default`)
      .then((res) => {
        var fileName = res.data.defaults;

        this.MainBk.src = `/api1/static/main_video/${fileName}`;
      })
      .catch((err) => {});

    Axios.get(`/api1/file_mgr/directory_listing?category=gallery`)
      .then((res) => {
        var fNames = [];

        for (var i = 0; i < res.data.data.length; i++) {
          fNames.push(res.data.data[i].name);
        }

        this.setState({ gallery_file: fNames });
      })
      .catch((err) => {});
  };

  render = () => {
    const list = this.slidemenu;
    return (
      <div>
        <div id="videoSection" className="sectionOne">
          <div className="pimg1" style={{ lineHeight: 0 }}>
            <div className="ptext2_2">
	  	<div>
              		<span>SEA Asia Pacific (Malaysia)</span><br />
			<img src="./inner_logo/ias_logo.png" className="img-fluid" alt="ias_logo" />
		</div>
            </div>
            <div>
              <video
                id="index_video"
                className="video-background"
                playsInline
                autoPlay
                loop
                ref={(e) => (this.MainBk = e)}
                muted
              />
            </div>
          </div>
        </div>
        <div id="slideSection">
          <div className="pimg2">
            {this.state.gallery_file.length > 0 ? (
              <Carousel
                showArrows={true}
                infiniteLoop={true}
                showThumbs={false}
                autoPlay={true}
                dynamicHeight={true}
                interval={5000}
                transitionTime={1000}
                showStatus={false}
                emulateTouch={true}
                className="index_slide"
              >
                {this.state.gallery_file.map((val, ind) => {
                  return (
                    <div key={ind}>
                      <img
                        src={`/api1/static/gallery/${val}`}
                        width="100%"
                        height="600px"
                      />
                    </div>
                  );
                })}
              </Carousel>
            ) : null}

            <div className="ptext">
              <div className="card text-white card-resize float-right">
                <div className="card-body">
                  <p id="intro" />
                  <br />
                  <Link
                    to="/about"
                    className="btn btn-warning btn-more-resize"
                    style={{
                      padding: 10,
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'white',
                      width: '100%',
                      letterSpacing: 1.5,
                    }}
                  >
                    <span className="more_btn" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="businessSection">
          <div className="pimg3">
            <div className="ptext2 glass-override">
              <span id="sub_1">Business We Provide</span>
            </div>
          </div>

          <section className="section section-one">
            <ScrollMenu
              data={list}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              menuClass={'prod_sect prod_animate'}
              menuStyle={{ height: '100%', width: '100%' }}
              wrapperStyle={{ height: '100%' }}
              alignCenter={false}
              hideSingleArrow={true}
            />
          </section>
        </div>
        <div id="globeSection">
          <div className="pimg4">
            <div className="ptext2">
              <span id="sub_2">Global Trading</span>
            </div>
          </div>

          <section className="section section-two">
            <div className="container-fluid globe-card">
              <div className="row">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-body d-flex flex-column">
                      <a
                        href="https://www.innolas-solutions.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="./inner_logo/innolas_logo.gif"
                          className="globetrade-logo img-fluid"
                          alt="innolas_logo"
                        />
                      </a>
                      <br />
                      <br />
                      <h4 className="card-title">InnoLas Solutions GmbH</h4>
                      <p className="card-text" id="globe_1_desc">
                        InnoLas Solution was founded in October 2013 as a
                        demerger of InnoLas PV and electronic business and looks
                        back on over 20 years experience in laser technology.
                      </p>
                      <a
                        href="https://www.innolas-solutions.com/innolas-solutions-laser-technology/"
                        target="_blank"
                        className="btn btn-warning globe-btn-more mt-auto"
                        rel="noopener noreferrer"
                      >
                        <span className="more_btn" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-body d-flex flex-column">
                      <a
                        href="https://www.mazurczak.de/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="./inner_logo/mazurczak_logo.gif"
                          className="globetrade-logo img-fluid"
                          alt="mazurczak_logo"
                        />
                      </a>
                      <br />
                      <br />
                      <h4 className="card-title">Mazurczak GmbH</h4>
                      <p className="card-text" id="globe_2_desc">
                        Our concentration on the needs of our customer in
                        heating, cooling and controlling of liquids has been an
                        essential element of our company's philosophy since it
                        was established in 1935 by Wilhelm Mazurczak.
                      </p>
                      <a
                        href="https://www.mazurczak.de/en/Profil/About-Us"
                        target="_blank"
                        className="btn btn-warning globe-btn-more mt-auto"
                        rel="noopener noreferrer"
                      >
                        <span className="more_btn" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-body d-flex flex-column">
                      <a
                        href="http://www.warwick-ins.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="./inner_logo/warwick_logo.gif"
                          className="globetrade-logo img-fluid"
                          alt="warwick_logo"
                        />
                      </a>
                      <br />
                      <br />
                      <h4 className="card-title">Warwick Instruments, Ltd.</h4>
                      <p className="card-text" id="globe_3_desc">
                        WARWICK Instruments, Ltd. is a fast-growing provider of
                        broadest flow products in high-technology applications
                        as diverse as semiconductor, solar cell, flat panel,
                        data storage, glass, industrial coatings, and analytical
                        instrumentation, etc. WARWICK's product includes high
                        quality flow measurement & controller, gas process
                        solution and global support & service around the world.
                        Leveraging our unsurpassed technology in more advanced
                        manufacturing application markets, WARWICK devotes in
                        maximizing provess potenncy and lowering productivity
                        cost for customers.
                      </p>
                      <a
                        href="http://www.warwick-ins.com/company-eg.html"
                        target="_blank"
                        className="btn btn-warning globe-btn-more mt-auto"
                        rel="noopener noreferrer"
                      >
                        <span className="more_btn" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  };
}

export default Home;
