import React, { Component } from 'react';
import $ from 'jquery';
import './add_on.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

class AutonomousRobot extends Component {
  constructor(prop) {
    super(prop);

    this.ttl_item = ['p', 'c', 'd', 'm'];

    this.state = {
      carousel_item: [],
    };
  }

  componentDidMount() {
    var langs = JSON.parse(window.localStorage.getItem('language_data'));
    var data = [];

    for (var keys in langs.AutonomousRobot) {
      $(`#${keys}`).html(langs.AutonomousRobot[keys]);

      if (/^auto_2_/.test(keys) == true) {
        data.push(langs.AutonomousRobot[keys]);
      }
    }

    this.setState({ carousel_item: data });
  }

  render = () => {
    return (
      <div className="auto_robot">
        <div className="row r_1">
          <div className="col-xl">
            <img
              src={require('../images/arrange/1.png')}
              className="img-fluid"
            />
            <h2 id="auto_list_1"></h2>
          </div>
        </div>

        <div className="row r_2">
          <div className="col-xl-6">
            <p id="auto_1_p1"></p>
            <p id="auto_1_p2"></p>
            <p id="auto_1_p3"></p>
            <p id="auto_1_p4"></p>
            <p>
              <button
                className="btn btn-danger"
                data-toggle="modal"
                data-target="#video_modal_auto"
              >
                Video Here
              </button>
            </p>
          </div>
          <div className="col-xl-6"></div>
        </div>

        <div className="row r_3">
          <div className="col-xl">
            <img
              src={require('../images/arrange/2.png')}
              className="img-fluid"
            />
            <h2 id="auto_list_2"></h2>
          </div>
        </div>

        <div className="row r_4">
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={false}
            dynamicHeight={true}
            emulateTouch={true}
            showStatus={false}
            className={'autorobot_slide'}
            axis={'vertical'}
          >
            {this.ttl_item.map((val, ind) => {
              return (
                <div className="row" key={ind}>
                  <div className="col-md-6 first">
                    <img
                      src={require(`../images/autorobot/scorpion-${val}-series-2020-05.jpg`)}
                    />
                  </div>
                  <div className="col-md-6 sec">
                    <h3>{this.state.carousel_item[ind * 4]}</h3>
                    <ul>
                      <li>{this.state.carousel_item[ind * 4 + 1]}</li>
                      <li>{this.state.carousel_item[ind * 4 + 2]}</li>
                      <li>{this.state.carousel_item[ind * 4 + 3]}</li>
                    </ul>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="row r_5">
          <div className="col-xl">
            <img
              src={require('../images/arrange/3.png')}
              className="img-fluid"
            />
            <h2 id="auto_list_3"></h2>
          </div>
        </div>

        <div className="row r_6">
          <div className="col-xl-6">
            <ul>
              <li id="auto_3_l1"></li>
              <li id="auto_3_l2"></li>
              <li id="auto_3_l3"></li>
              <li id="auto_3_l4"></li>
            </ul>
          </div>
          <div className="col-xl-6"></div>
        </div>

        <div className="row r_7">
          <div className="col-xl">
            <img
              src={require('../images/arrange/4.png')}
              className="img-fluid"
            />
            <h2 id="auto_list_4"></h2>
          </div>
        </div>

        <div className="row r_8">
          <div className="col-xl-6">
            <h3 id="auto_4_mh1"></h3>
            <p>
              <strong id="auto_4_l1_h"></strong>
              <br />
              <span id="auto_4_l1_c"></span>
            </p>
            <p>
              <strong id="auto_4_l2_h"></strong>
              <br />
              <span id="auto_4_l2_c"></span>
            </p>
          </div>
          <div className="col-xl-6">
            <h3 id="auto_4_mh2"></h3>
            <p>
              <strong id="auto_4_l3_h"></strong>
              <br />
              <span id="auto_4_l3_c"></span>
            </p>
            <p>
              <strong id="auto_4_l4_h"></strong>
              <br />
              <span id="auto_4_l4_c"></span>
            </p>
            <p>
              <strong id="auto_4_l5_h"></strong>
              <br />
              <span id="auto_4_l5_c"></span>
            </p>
          </div>
        </div>

        <AutoVideoModal />
      </div>
    );
  };
}

export default AutonomousRobot;

class AutoVideoModal extends Component {
  render = () => {
    return (
      <div className="modal fade" role="dialog" id="video_modal_auto">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <iframe
              width="100%"
              height="720"
              src="https://www.youtube.com/embed/haqMQ7t7_mE"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  };
}
